@import '~styles/base.scss';

//// Include fonts here..

:global {
  @import '~styles/forms.scss';
  @import '~styles/typography.scss';

  body {
    font-family: 'Roboto';
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility !important;
    overflow-y: scroll;
    font-size: 14px;
    color: $color-text;
    line-height: 1.5;
    //background-color: $color-body-bg;
  }

  //* {
  //  margin: 0;
  //  padding: 0;
  //  box-sizing: border-box;
  //}
  //
  //a {
  //  color: $color-primary;
  //  text-decoration: none;
  //}
}

.component {
}
