@import '~styles/base.module.scss';

.modalContainer {
  max-width: 472px !important;
  width: 472px !important;
  flex-basis: 35em;

  max-height: 100%;
  margin: 30px auto;
  box-shadow: 2px;
  padding: 1px 4px;
  z-index: 2000;
  background-color: white;
  border: 2px solid $color-dark-gray;
  border-radius: 32px;

  overflow-y: auto !important;

  @include media('<small') {
    position: c center;
    width: 100% !important;
    margin-top: 56px !important;
    border-radius: 0 !important;
    height: 100% !important;
    border: none !important;
    overflow: auto;
    padding: 20px !important;
    backdrop-filter: none !important;
  }
}

.modal {
  margin: 75px;
  margin-top: 10px;
  margin-bottom: 15px;
  @include media('<small') {
    height: 750px !important;
    margin: $space-s !important;
    overflow: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
}

.header {
  margin: 7% 5% 3% 13%;
  font-weight: bold;
  font-size: 1.4em;
  justify-content: first baseline;
  @include media('<small') {
    margin: 0% 5% 4% 2%;
  }
}

.content {
  height: 100% !important;
  padding-top: $space-m !important;
  padding-bottom: $space-l !important;
  width: 85%;
  margin: 0 auto;
}

.deviceID {
  font-weight: 500;
}

.backdrop {
  backdrop-filter: blur(3px);
}

.buttonsWrapper {
  text-align: center;
  margin-bottom: $space-s;
  display: flex;
  padding: $space-s;
}

.buttonCancelar {
  width: 50%;
  background-color: $color-white;
  color: $color-primary;
  border: none !important;
  box-shadow: none !important;
  margin-top: none !important;
  padding-top: none !important;
  outline: none !important;
  border-radius: 30px;
  &:hover {
    background-color: rgba($color-primary, 0.2);
  }
  &:disabled {
    color: $color-extra-light-gray;
  }
}

.submitButton {
  width: 50%;
  border-radius: 30px !important;
  background-color: $color-primary !important;
  color: $color-white !important;
  align-items: center !important;
  margin-bottom: 0 !important;

  &:hover {
    background-color: $color-primary-dark !important;
  }
  &:focus {
    border-color: transparent !important;
    outline: none;
    border: none;
  }
}
