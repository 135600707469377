.form-group {
  margin-bottom: $space-m;
  position: relative;

  flex-grow: 1;

  @include media('<tablet') {
    width: 100% !important;
    max-width: none !important;
    margin-right: 0 !important;
  }
}

.form-group-row {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;

  .form-group {
    margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

// Remove ugly chrome yellow autofill background
input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
}
