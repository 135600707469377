.component {
  position: fixed;
  z-index: 9999999;
  bottom: 8px;
  left: 8px;
  padding-right: 8px;
  pointer-events: none;
}

.content {
  display: flex;
  align-items: center;
  padding: 1em;

  background-color: black;
  color: white;
  font-size: 14px;
  font-weight: 600;

  backface-visibility: hidden;
  pointer-events: none;
}

.fade {
  opacity: 0;
  transform: scale(0.2);
  transition: opacity 300ms ease, transform 300ms cubic-bezier(0.59, -0.57, 0.46, 1.41);

  &--entering {
    opacity: 0;
    transform: scale(0.2);
  }

  &--entered {
    opacity: 1;
    transform: scale(1);
  }

  &--exiting {
    opacity: 0;
    transform: scale(0.2);
  }
}
