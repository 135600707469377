// Colors
$color-text: #333333;

// Breakpoints
$breakpoints: (
  phone: 320px,
  tablet: 768px,
  desktop: 1024px,
  medium: 768px,
  small: 500px
);

// Spacing
$space-xxs: 2px;
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 32px;
$space-xl: 64px;
$space-xxl: 128px;
